import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import CopyIcon from 'mdi-react/ContentCopyIcon'
import IconButton from '@material-ui/core/IconButton'

export const CopyContentButton = ({
  content,
  tooltip = 'Copy to Clipboard'
}) => {
  const [title, setTitle] = useState('')

  return (
    <Tooltip
      title={title || tooltip}
      placement="top"
      onClick={() => {
        navigator.clipboard.writeText(content)
        setTitle(`Copied: ${content}`)
      }}>
      <IconButton>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  )
}

CopyContentButton.propTypes = {
  content: PropTypes.string,
  tooltip: PropTypes.string
}
