import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ChevronDown from 'mdi-react/ChevronDownIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon'
import UsersIcon from 'mdi-react/UsersIcon'
import Link from './Link'
import SearchQuerySummary from './SearchQuerySummary'
import { channelLink } from '../links'
import can, { canScoped } from '../utilities/can'
import { CopyContentButton } from './CopyContentButton'

const Wrapper = styled.div`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .channel {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
    line-height: 28px;
    :after {
      content: ${props => (props.disabled ? '" (disabled)"' : '')};
    }
  }

  .chip {
    flex-shrink: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  button:disabled svg {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`

class ChannelListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      syncingFrost: false,
      syncingBuzz: false,
      syncingBuzz2: false
    }
  }

  async handleSyncFrost() {
    try {
      this.setState({ syncingFrost: true })
      await this.props.onChannelSyncFrost(this.props.channel)
    } catch (e) {
      console.error(e)
      alert('Sync failed')
    } finally {
      this.setState({ syncingFrost: false })
    }
  }

  async handleSyncBuzz() {
    try {
      this.setState({ syncingBuzz: true })
      await this.props.onChannelSyncBuzz(this.props.channel)
    } catch (e) {
      console.error(e)
      alert('Sync failed')
    } finally {
      this.setState({ syncingBuzz: false })
    }
  }

  async handleSyncBuzz2() {
    try {
      this.setState({ syncingBuzz2: true })
      await this.props.onChannelSyncBuzz2(this.props.channel)
    } catch (e) {
      console.error(e)
      alert('Sync failed')
    } finally {
      this.setState({ syncingBuzz2: false })
    }
  }

  render() {
    return (
      <Wrapper disabled={this.props.channel.disabled}>
        <Container disabled={this.props.channel.disabled}>
          <div className="details">
            <div className="channel">
              <div className="name">
                <Link to={channelLink(this.props.channel, '')}>
                  {this.props.channel.name}{' '}
                  {this.props.channel.salesforceSiteId
                    ? ' - ' + this.props.channel.salesforceSiteId
                    : ''}
                </Link>
              </div>
              <div className="query">
                {can(this.props.user, 'CHANNELS_MANAGE_ALL') && (
                  <SearchQuerySummary query={this.props.channel.coursesQuery} />
                )}
              </div>
            </div>
          </div>
          <div className="edit">
            {this.props.channel.taas &&
              canScoped(
                this.props.user,
                'CHANNELS_MANAGE_TAAS_ALL',
                'CHANNELS_MANAGE_TAAS_ORG',
                () =>
                  this.props.channel.organization ===
                  this.props.user.organization.id
              ) && (
                <IconButton
                  onClick={() => this.props.onChannelTaas(this.props.channel)}>
                  <UsersIcon />
                  <small>Student Dashboard</small>
                </IconButton>
              )}

            <CopyContentButton
              content={this.props.channel.id}
              tooltip="Copy Channel ID"
            />

            <IconButton
              onClick={() => this.props.onChannelEdit(this.props.channel)}>
              <PencilIcon />
            </IconButton>

            {can(this.props.user, 'CHANNELS_VIEW_REPORTS') && (
              <IconButton
                onClick={() => this.props.onChannelReports(this.props.channel)}>
                <ViewDashboardIcon />
              </IconButton>
            )}
          </div>
        </Container>
        <div
          style={{
            marginTop: '-24px',
            padding: '0 24px 24px 24px',
            clear: 'both'
          }}>
          {(this.props.channel.buzzDisplayDomain ||
            this.props.channel.buzz2DisplayDomain) && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="panel-buzz-content"
                id="panel-buzz-header">
                Buzz
              </AccordionSummary>
              <AccordionDetails id="panel-buzz-content">
                <TableContainer component={Paper}>
                  <Table aria-label="Buzz Details">
                    <TableHead>
                      <TableRow>
                        {this.props.channel.buzzDisplayDomain && (
                          <TableCell>Buzz Domain</TableCell>
                        )}
                        {this.props.channel.buzz2DisplayDomain && (
                          <TableCell>Buzz2 Domain</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {this.props.channel.buzzDisplayDomain && (
                          <TableCell>
                            <StyledAnchor
                              target="_blank"
                              href={this.props.channel.buzzDisplayDomain}>
                              {this.props.channel.buzzDisplayDomain}
                            </StyledAnchor>
                          </TableCell>
                        )}
                        {this.props.channel.buzz2DisplayDomain && (
                          <TableCell>
                            <StyledAnchor
                              target="_blank"
                              href={this.props.channel.buzz2DisplayDomain}>
                              {this.props.channel.buzz2DisplayDomain}
                            </StyledAnchor>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}
          {this.props.channel.googleClassroomDomains?.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="panel-glassroom-content"
                id="panel-glassroom-header">
                Google Classroom
              </AccordionSummary>
              <AccordionDetails id="panel-glassroom-content">
                <TableContainer component={Paper}>
                  <Table aria-label="Google Classroom Details">
                    <TableHead>
                      <TableRow>
                        <TableCell>Domain</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.channel.googleClassroomDomains.map(domain => (
                        <TableRow key={domain}>
                          <TableCell>
                            <StyledAnchor target="_blank" href={domain}>
                              {domain}
                            </StyledAnchor>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}
          {this.props.channel.ltiKey && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="panel-lti1p1-content"
                id="panel-lti1p1-header">
                LTI 1.1
              </AccordionSummary>
              <AccordionDetails id="panel-lti1p1-content">
                <TableContainer component={Paper}>
                  <Table aria-label="LTI 1.1 Details">
                    <TableHead>
                      <TableRow>
                        <TableCell>URL</TableCell>
                        <TableCell>Key</TableCell>
                        <TableCell>Secret</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <code>lti.edynamiclearning.com</code>
                        </TableCell>
                        <TableCell>
                          <code>{this.props.channel.ltiKey.key}</code>
                          <CopyContentButton
                            content={this.props.channel.ltiKey.key}
                            tooltip="Copy LTI Key"
                          />
                        </TableCell>
                        <TableCell>
                          <code>{this.props.channel.ltiKey.secret}</code>
                          <CopyContentButton
                            content={this.props.channel.ltiKey.secret}
                            tooltip="Copy LTI Secret"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </Wrapper>
    )
  }
}

ChannelListItem.defaultProps = {}

ChannelListItem.propTypes = {
  user: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  channel: PropTypes.object.isRequired,
  onChannelEdit: PropTypes.func.isRequired,
  onChannelSyncFrost: PropTypes.func.isRequired,
  onChannelSyncBuzz: PropTypes.func.isRequired,
  onChannelUsers: PropTypes.func.isRequired,
  onChannelTaas: PropTypes.func.isRequired,
  onChannelReports: PropTypes.func.isRequired,
  onChannelSyncBuzz2: PropTypes.func.isRequired
}

export default ChannelListItem
